import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  field_wrapper: {
    marginBottom: '2rem'
  },
  hidden: {
    display: 'none'
  },
  fieldComment: {
    marginBottom: '1rem !important'
  },
  relative: {
    position: 'relative'
  },
  absolute: {
    position: 'absolute'
  }
}));
