import React from 'react';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
import ErrorBoundary from '../../../containers/ErrorBoundary';
import { IChildrenProps } from '../../../interfaces';

import { useStyles } from '../styles';

interface IModalWindowBodyProps extends IChildrenProps{
  closeModalWindow: () => void,
  error?: boolean,
  title?: string | number | null,
  text?: string | number | null,
}
const ModalWindowBody = ({ closeModalWindow, error, title, text, children }:IModalWindowBodyProps) => {
  const classes = useStyles();
  return (
     <div className={clsx(classes.modal, {
       [classes.error]: error
     })}
     >
        <CloseIcon
           className={classes.close}
           onClick={closeModalWindow}
           role="presentation"
        />

        <ErrorBoundary>
           {title && (
           <h5
              className={classes.title}
           >
              {title}
           </h5>
           )}
           {text && <p className={classes.message}>{text}</p>}
           {children && children}
        </ErrorBoundary>
     </div>
  );
};

export default ModalWindowBody;
