import { baseUrls } from '@monorepo/common';

export const endpoints = {
  homeUrl: baseUrls.api,
  trialSignup: '/Account/TrialSignup',
  googleLogin: '/Account/GoogleLogin',
  activation: '/Account/TrialActivation',
  regions: '/Account/GetRegionsList',
  isAccountNameAvailable: '/Account/IsAccountNameAvailable',
  resendActivationEmail: '/Account/ResendActivationEmail',
  accountCreate: '/Account/CreateTrialAccount',
  accountConfigurationUpdate: '/Account/UpdateAccountConfiguration',
  successLink: '/Account/SuccessLink',
};
