import { memo, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import cookies from 'js-cookie';
import { Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { cookieNames } from '@monorepo/common';
import StepIcon from './components/StepIcon';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import Finish from './steps/Finish';
import { useStyles } from './styles';

const Home = () => {
  const classes = useStyles();
  const { trialGuid: trialGuidParam, code: codeParam } = useParams();
  const trialGuidCookie = cookies.get(cookieNames.trial_guid);

  const [activeStep, setActiveStep] = useState(codeParam ? 1 : +cookies.get(cookieNames.active_step)! || 0);
  const [trialGuid, setTrialGuid] = useState(trialGuidParam || trialGuidCookie || '');
  const [region, setRegion] = useState(+cookies.get(cookieNames.region_id)! || 1);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const steps = [
    {
      label: 'Start',
      content: (
        <Step1
          onNext={handleNext}
          setTrialGuid={setTrialGuid}
        />
      ),
    },
    {
      label: 'Activate your trial',
      content: (
        <Step2
          onNext={handleNext}
          trialGuid={trialGuid}
          code={codeParam}
        />
      ),
    },
    {
      label: 'Set up trial',
      content: (
        <Step3
          onNext={handleNext}
          trialGuid={trialGuid}
          setRegionId={setRegion}
        />
      ),
    },
    {
      label: 'Account configuration',
      content: (
        <Step4
          onNext={handleNext}
          trialGuid={trialGuid}
          regionId={region}
        />
      ),
    },
  ];

  useEffect(() => {
    if (activeStep) {
      cookies.set(cookieNames.active_step, activeStep.toString());
    }
  }, [activeStep]);

  useEffect(() => {
    if (trialGuid) {
      cookies.set(cookieNames.trial_guid, trialGuid);
    }
  }, [trialGuid]);

  useEffect(() => {
    if (region) {
      cookies.set(cookieNames.region_id, region.toString());
    }
  }, [region]);

  return (
    <>
      <Helmet>
        <title>
          Signup for Checkbox trial
        </title>
      </Helmet>
      <div className={classes.root}>
        {activeStep < 4 ? (
          <>
            <div className={classes.description_page}>
              <Typography
                sx={{ mb: 3 }}
                variant="h4"
              >
                Signup for Checkbox trial
              </Typography>
              <p>Try out all of Checkbox’s features completely free for 14 days in the cloud. No obligation or credit card required.</p>
              <p>
                <span>Want to install it on your own server instead? </span>
                <a href="https://www.checkbox.com/free-checkbox-trial/on-premises-survey-software/">Download a free trial of Checkbox Survey On-Premises version</a>
              </p>
              <ul>
                <li>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </li>
                <li>
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </li>
                <li>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </li>
              </ul>
            </div>
            <div className={classes.wrapper_for_steps}>
              <Stepper
                activeStep={activeStep}
                orientation="vertical"
                sx={{
                  '& .MuiStepConnector-line': {
                    ml: 1,
                    borderLeftColor: 'divider',
                    borderLeftWidth: 2
                  }
                }}
              >
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel StepIconComponent={StepIcon}>
                      <Typography
                        sx={{ ml: 2 }}
                        variant="overline"
                      >
                        {step.label}
                      </Typography>
                    </StepLabel>
                    <StepContent
                      className={clsx({
                        [classes.active_step]: activeStep === index
                      })}
                    >
                      {step.content}
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </div>
          </>
        ) : (
          <Finish
            trialGuid={trialGuid}
            regionId={region}
          />
        )}
      </div>
    </>
  );
};

export default memo(Home);
