import React, { memo, useEffect, useState } from 'react';
import { Portal, Snackbar } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Alert } from '@mui/material';
import clsx from 'clsx';
import { closeNotification } from '../../features/ui/sliceNotification';
import { useAppSelector } from '../../app/hooks';
import { useStyles } from './styles';

// interface NotificationProps {}
const Notification = () => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { text, type, show, fixed, positionCenter, closeDelay } = useAppSelector(({ notification }) => ({
    type: notification.type,
    text: notification.text,
    show: notification.show,
    fixed: notification.fixed,
    positionCenter: notification.positionCenter,
    closeDelay: notification.delayClose
  }));

  const handleClose = () => {
    setOpen(false);
    dispatch(closeNotification());
  };

  useEffect(() => {
    if (closeDelay) {
      setTimeout(() => {
        if (show && !fixed) {
          dispatch(closeNotification());
        }
      }, closeDelay);
    }
  }, [show, closeDelay]);

  return (
    <Portal>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={!fixed ? handleClose : () => { }}
        className={clsx({
          [classes.positionCenter]: positionCenter
        })}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={type}
          className={classes.message}
        >
          {text}
        </Alert>
      </Snackbar>
    </Portal>
  );
};

export default memo(Notification);
