import { makeStyles } from '@material-ui/core';
import { mixin } from '../../utils/mixin';

export const useStyles = makeStyles(() => ({
  root: {
    padding: '2rem 15px 2rem',
    ...mixin.flex('center', 'space-between')
  },
  pagination_wrapper: {
    '& ul': {
      ...mixin.flex('center', 'center')
    }
  },
  count: {
    color: '#a5a5a5',
    fontSize: '1.1rem !important'
  }
}));
