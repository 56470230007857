import React, { Component } from 'react';
import ErrorText from './ErrorText';

export default class ErrorBoundary extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = { errorComponent: false };

  componentDidCatch() {
    // componentDidCatch(err: any, info: any) {
    // console.log("~~~~Error~~~~~", err);
    // console.log("~~~~Info~~~~~", info);

    this.setState({ errorComponent: true });
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    if (this.state.errorComponent) {
      return <ErrorText text={"Couldn't upload this widget"} />;
    }
    // eslint-disable-next-line react/prop-types,react/destructuring-assignment
    return <>{this.props.children}</>;
  }
}
