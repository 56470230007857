import { makeStyles } from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Mixin } from '@monorepo/common';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },

  page_head: {
    padding: '0 5px',
    width: '100%',
    maxWidth: '62.5rem',
    margin: '0 auto',
    ...Mixin.mixin.flex('center', 'space-between'),
    flexWrap: 'wrap',
    '@media(max-width: 992px)': {
      flexDirection: 'column',
      gridGap: 15,
      textAlign: 'center'
    },
  },
  page_head_offset: {
    margin: '0 auto 2rem',
  },

  lateral_padding: {},

  internal_block: {
    width: '100%',
    maxWidth: '62.5rem',
    margin: '0 auto',
    padding: '5px 30px 30px',
    borderRadius: theme.shape.borderRadius,
    '&$lateral_padding': {
      padding: '5px 0 30px'
    },
    '@media(max-width: 768px)': {
      padding: '5px 10px 15px',
    },
    '& h4': {
      '@media(max-width: 768px)': {
        fontSize: '1.3rem'
      }
    },
    '& form': {
      paddingTop: 20
    }
  },
  titleCenter: {
    textAlign: 'center',
  },
  fullWidth: {
    maxWidth: '100%',
  },
  center: {
    textAlign: 'center'
  },
  noOverflow: {
    overflow: 'visible'
  },
  headerTitle: {
    fontSize: '1.9rem'
  },
  wrapper_for_head: {
    width: '100%',
    ...Mixin.mixin.flex('center', 'space-between'),
    '@media(max-width: 600px)': {
      flexDirection: 'column',
      '& > *:first-child': {
        marginBottom: '1rem'
      }
    }
  },
}));
