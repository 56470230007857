import { makeStyles } from '@material-ui/core';
import { Mixin } from '@monorepo/common';

export const useStyles = makeStyles(() => ({
  signup_form: {
    maxWidth: '27rem',
    textAlign: 'center',
    '& h5': {
      marginBottom: '20px',
    },
  },
  googleBtn: {
    marginTop: '1rem',
    width: '100%',
    justifyContent: 'center'
  },
  root: {
    paddingTop: '2rem',
    ...Mixin.mixin.flex('flex-start', 'center'),
    '@media(max-width: 1200px)': {
      display: 'block'
    },
    '& a': {
      fontWeight: 'bold',
      color: 'inherit',
      borderBottom: '1px solid #CED2D9',
    },
  },
  description_page: {
    width: '100%',
    maxWidth: '30rem',
    marginRight: '4rem',
    order: -1,
    '@media(max-width: 1200px)': {
      maxWidth: '100%',
      marginTop: '2rem',
    },
    '@media(max-width: 768px)': {

    },
    '& p, & b,& li': {
      fontSize: 16
    },
    '& ul': {
      paddingLeft: '1.3rem'
    },
    '& p, & ul': {
      marginBottom: '1rem'
    },
    '& li': {
      marginBottom: '.7rem'
    },
  },
  wrapper_for_steps: {
    width: '100%',
    maxWidth: '50rem',
    '& .MuiAvatar-root': {
      backgroundColor: '#a9a9a9'
    },
    '& .Mui-completed  .MuiAvatar-root': {
      backgroundColor: '#4bb34b'
    },
    '@media(max-width: 1200px)': {
      maxWidth: '100%',
    }
  },
  active_step: {
    padding: '32px 8px 32px 20px',
    borderLeft: '2px solid rgba(0, 0, 0, 0.12) !important',
    marginLeft: '20px !important'
  },
  region_select: {
    textAlign: 'left',
  },
  checkbox_wrapper: {
    padding: '20px 0',
    '& > span': {
      float: 'left',
      padding: '0 10px 0 0',
    },
    '& > p': {
      textAlign: 'left',
    },
  },
  signup_complete: {
    padding: '40px',
    '& h4': {
      marginBottom: '16px',
    },
    '& p': {
      marginBottom: '12px'
    },
  },
  signup_complete_info: {
    background: '#e2e2e2',
    borderRadius: '10px',
    padding: '30px 40px 20px',
  }
}));
