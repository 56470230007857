import { makeStyles, Theme } from '@material-ui/core';
import { mixin } from '../../utils/mixin';

export const useStyles = makeStyles((theme: Theme) => ({
  error_text: {
    ...mixin.flex(),
    padding: '1rem 0',
    '& svg, & span': {
      marginRight: '.5rem',
      ...mixin.fontText(1.4, 1.5, 'normal', theme.palette.error.main),
    },
  },
}));
