import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { endpoints } from '../../constants/endpoints';
import { ISelectListItem, ITrialResp, ITrialPost, IGooglePost, IActivationPost, TrialAccountResp, ISetupPost, IConfigurationPost, IAccountNameCheck, ISuccessLink, TrialSignupSuccsessResp } from './interfaces';

export const trialApi = createApi({
  reducerPath: 'trialApi',
  tagTypes: ['trial'],
  baseQuery: fetchBaseQuery({
    baseUrl: endpoints.homeUrl,
  }),
  endpoints: (build) => ({
    getRegions: build.query<ISelectListItem[], string>({
      query: () => ({
        url: endpoints.regions,
      }),
    }),
    isAccountNameAvailable: build.query<boolean, IAccountNameCheck>({
      query: ({
        regionId,
        accountName
      }) => ({
        url: endpoints.isAccountNameAvailable,
        params: {
          regionId,
          accountName
        }
      }),
    }),
    resendActivationEmail: build.query<boolean, string>({
      query: (trialGuid) => ({
        url: `${endpoints.resendActivationEmail}/${trialGuid}`,
      }),
    }),
    successLink: build.query<TrialSignupSuccsessResp, ISuccessLink>({
      query: ({
        regionId,
        trialGuid
      }) => ({
        url: `${endpoints.successLink}/${regionId}/${trialGuid}`
      }),
    }),
    trialPost: build.mutation<ITrialResp, ITrialPost>({
      query: ({ email }) => ({
        url: endpoints.trialSignup,
        method: 'POST',
        body: { email }
      }),
    }),
    googlePost: build.mutation<ITrialResp, IGooglePost>({
      query: ({ jwtFromGoogle }) => ({
        url: endpoints.googleLogin,
        method: 'POST',
        body: { jwtFromGoogle }
      }),
    }),
    activationPost: build.mutation<string, IActivationPost>({
      query: ({ trialGuid, code }) => ({
        url: endpoints.activation,
        method: 'POST',
        body: { trialGuid, code }
      }),
    }),
    setupPost: build.mutation<TrialAccountResp, ISetupPost>({
      query: ({ trialGuid, accountName, regionId }) => ({
        url: `${endpoints.accountCreate}/${trialGuid}`,
        method: 'POST',
        body: { accountName, regionId }
      }),
    }),
    configurationPost: build.mutation<string, IConfigurationPost>({
      query: ({
        trialGuid,
        regionId,
        firstName,
        lastName,
        company,
        title,
        phone
      }) => ({
        url: endpoints.accountConfigurationUpdate,
        method: 'POST',
        body: {
          trialGuid,
          regionId,
          firstName,
          lastName,
          company,
          title,
          phone
        }
      }),
    }),
  }),
});

export const {
  useGetRegionsQuery,
  useIsAccountNameAvailableQuery,
  useResendActivationEmailQuery,
  useSuccessLinkQuery,
  useTrialPostMutation,
  useGooglePostMutation,
  useActivationPostMutation,
  useSetupPostMutation,
  useConfigurationPostMutation,
} = trialApi;
