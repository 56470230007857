import { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Button, Typography } from '@mui/material';
import { FormField, Loader } from '@monorepo/common';
import { useActivationPostMutation, useResendActivationEmailQuery } from '../../../../services/trial';
import { showNotification } from '../../../../features/ui/sliceNotification';
import { ArrowRightIcon } from '../../components/ArrowRightIcon';
import { IEventsStepProps } from '../../interfaceSteps';
import { schema } from './schema';
import { useStyles } from '../../styles';

interface IStep2Props extends IEventsStepProps {
  trialGuid: string,
  code: string | undefined,
}

interface IActivationFormProps {
  code: string,
}

const Step2 = ({ onNext, trialGuid, code }: IStep2Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [resend, setResend] = useState(false);
  const [isResendActive, setIsResendActive] = useState(false);

  const [post, { isLoading }] = useActivationPostMutation();
  const { refetch, isFetching: isResendingEmail, isSuccess, error } = useResendActivationEmailQuery(trialGuid, { skip: !resend });

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<IActivationFormProps>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IActivationFormProps) => {
    post({ trialGuid: trialGuid!, ...data }).then((res: any) => {
      if (res.error) {
        dispatch(showNotification({
          type: 'error',
          show: true,
          text: res.error?.data || 'Error'
        }));
      } else {
        onNext();
      }
    });
  };

  const startResendTimer = () => {
    setTimeout(() => {
      setIsResendActive(true);
    }, 10000);
  };

  const resendActivation = () => {
    if (resend) {
      refetch();
    } else {
      setResend(true);
    }

    setIsResendActive(false);
    startResendTimer();
  };

  if (trialGuid && code) {
    setValue('code', code);
    handleSubmit(onSubmit);
  }

  startResendTimer();

  useEffect(() => {
    if (isSuccess) {
      dispatch(showNotification({
        text: 'Resend successfully',
        type: 'success',
        show: true
      }));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      console.log(error);
      dispatch(showNotification({
        text: `${(error as FetchBaseQueryError)?.data}`,
        type: 'error',
        show: true
      }));
    }
  }, [error]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classes.signup_form}
    >
      <Loader isShow={isLoading || isResendingEmail} />
      <Typography variant="h5">
        Please enter the code from email to activate your trial
      </Typography>
      <FormField
        extraProps={{ ...register('code') }}
        errors={errors}
        label="Code *"
      />
      <Button
        variant="contained"
        type="submit"
        sx={{ mr: 2 }}
        disabled={!isResendActive}
        onClick={resendActivation}
      >
        Resend activation
      </Button>
      <Button
        endIcon={(<ArrowRightIcon fontSize="small" />)}
        variant="contained"
        type="submit"
      >
        Next step
      </Button>
    </form>
  );
};

export default memo(Step2);
