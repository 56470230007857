export interface IGlobalErrorReducer {
  status?: string | number | null,
  data?:any,
  message?: string | null
}
export const globalErrorInitialState:IGlobalErrorReducer = {
  data: null,
  status: null,
  message: null
};
