import { trialApi } from '../services/trial';

export const middlewareHandler = (getDefaultMiddleware: any) => {
  const middlewareList = [
    ...getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          'ui/modalWindow/showModalWindow',
        ],
      }
    })
      .concat(trialApi.middleware)
  ];
  return middlewareList;
};
