import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Typography } from '@mui/material';
import { FormField, Loader } from '@monorepo/common';
import { useConfigurationPostMutation } from '../../../../services/trial';
import { showNotification } from '../../../../features/ui/sliceNotification';
import { IEventsStepProps } from '../../interfaceSteps';
import { useStyles } from '../../styles';
import { schema } from './schema';

interface IStep4Props extends IEventsStepProps {
  trialGuid: string,
  regionId: number,
}

interface IConfigurationFormProps {
  firstName: string,
  lastName: string,
  company: string,
  title: string,
  phone: string,
}

const Step4 = ({ onNext, trialGuid, regionId }: IStep4Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [post, { isLoading }] = useConfigurationPostMutation();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IConfigurationFormProps>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IConfigurationFormProps) => {
    post({
      trialGuid: trialGuid!,
      regionId: +regionId!,
      firstName: data.firstName,
      lastName: data.lastName,
      company: data.company,
      title: data.title,
      phone: data.phone
    }).then((res: any) => {
      if (res.error) {
        dispatch(showNotification({
          type: 'error',
          show: true,
          text: res.error?.data || 'Error'
        }));
      } else {
        onNext();
      }
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classes.signup_form}
    >
      <Loader isShow={isLoading} />
      <Typography variant="h5">
        Let&apos;s configure your Checkbox account.
      </Typography>
      <FormField
        extraProps={{ ...register('firstName') }}
        errors={errors}
        label="First Name *"
      />
      <FormField
        extraProps={{ ...register('lastName') }}
        errors={errors}
        label="Last Name *"
      />
      <FormField
        extraProps={{ ...register('company') }}
        errors={errors}
        label="Company *"
      />
      <FormField
        extraProps={{ ...register('title') }}
        errors={errors}
        label="Title"
      />
      <FormField
        extraProps={{ ...register('phone') }}
        errors={errors}
        label="Phone"
      />
      <Button
        variant="contained"
        type="submit"
      >
        Finish
      </Button>
    </form>
  );
};

export default memo(Step4);
