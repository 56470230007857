import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import notification from '../features/ui/sliceNotification';

import globalError from '../features/sliceGlobalError';

import { middlewareHandler } from './middlewareList';
import { trialApi } from '../services/trial';

export const store = configureStore({
  middleware: (getDefaultMiddleware) => middlewareHandler(getDefaultMiddleware),
  reducer: {
    globalError,
    notification,
    [trialApi.reducerPath]: trialApi.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;
