import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotificationReducer, notificationInitialState } from './notificationInitialState';

export const slice = createSlice({
  name: 'notification',
  initialState: notificationInitialState,
  reducers: {
    showNotification: (state:INotificationReducer, action:PayloadAction<INotificationReducer>): void => {
      Object.assign(state, action.payload);
    },
    closeNotification: (state: INotificationReducer) => {
      state.show = false;
      state.type = 'info';
      state.text = '';
      state.fixed = false;
      state.positionCenter = false;
    }
  },
});
export const { showNotification, closeNotification } = slice.actions;
export default slice.reducer;
