import React, { ChangeEvent, memo } from 'react';
import { TextField, Typography } from '@mui/material';
import clsx from 'clsx';
import { IChildrenProps } from '../../interfaces';

import { useStyles } from './styles';

interface IField {
  field?: React.FormEvent<HTMLInputElement>,
  value?: string
}

interface FormFieldProps extends IChildrenProps {
  type?: 'text' | 'number' | 'checkbox' | 'radio' | 'hidden' | 'file' | 'textarea' | 'password',
  label?: string,
  description?: string,
  visible?: boolean,
  autoComplete?: boolean,
  fullWidth?: boolean,
  placeholder?: string,
  name?: string,
  size?: string,
  valueField?: string | null,
  errors?:any,
  fieldComment?:string | null,
  extraProps?:any,
  refProps?:any,
  position?:'relative' | 'absolute' | null,
  change?:(e: string, input?:ChangeEvent<HTMLInputElement>) => any,
  eventInput?:(e: string, input?:ChangeEvent<HTMLInputElement>) => any,
  focus?: (el:IField) => void,
  classNameForField?: string,
  classNameForWrapper?: string,
}

const FormField = ({
  extraProps = {},
  refProps = {},
  type = 'text',
  label,
  description,
  visible = true,
  focus,
  errors = false,
  fieldComment,
  fullWidth = true,
  placeholder,
  size,
  classNameForField,
  classNameForWrapper,
  change,
  position = 'relative',
  children,
  valueField,
  eventInput
}:FormFieldProps) => {
  const classes = useStyles();
  const changeField = (el:ChangeEvent<HTMLInputElement>) => {
    if (change) {
      change(el.target.value, el);
    }
  };
  const eventInputField = (el:ChangeEvent<HTMLInputElement>) => {
    if (eventInput) {
      eventInput(el.target.value, el);
    }
  };

  const focusField = (el:React.FormEvent<HTMLInputElement>) => {
    if (focus) {
      focus({ value: el.currentTarget.value });
    }
  };

  if (visible) {
    return (
      <div
        {...refProps}
        className={clsx(classes.field_wrapper, classNameForWrapper, {
          [classes.hidden]: type === 'hidden',
          [classes.relative]: position && position === 'relative',
          [classes.absolute]: position && position === 'absolute',
        })}
      >
        {fieldComment && (
          <Typography
            className={classes.fieldComment}
            color="textSecondary"
            variant="body1"
          >
            {fieldComment}
          </Typography>
        )}
        <TextField
          autoComplete="no"
          onFocus={focusField}
          className={clsx(classNameForField)}
          onChange={changeField}
          onInput={eventInputField}
          placeholder={placeholder}
          size={size}
          rows={type === 'textarea' ? 6 : 1}
          multiline={type === 'textarea' && true}
          type={type}
          error={!!errors[extraProps.name]}
          fullWidth={fullWidth}
          label={label}
          variant="outlined"
          {...extraProps}
          helperText={errors[extraProps.name] && errors[extraProps.name].message || description}
              // TODO remove this props  {shrink: true}
          InputLabelProps={{
            shrink: true,
          }}
          value={valueField}
        />
        {children}
      </div>
    );
  }
  return null;
};

export default memo(FormField);
