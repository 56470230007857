import merge from 'lodash/merge';
import { createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material/styles';
import type { Direction, Theme, ThemeOptions } from '@mui/material';
import { THEMES } from '../constants/forTheme';
import { lightThemeOptions } from './lightThemeOptions';
import { darkThemeOptions } from './darkThemeOptions';
import { baseThemeOptions } from './baseThemeOptions';

interface Neutral {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    neutral?: Neutral;
  }

  interface PaletteOptions {
    neutral?: Neutral;
  }
}

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  roundedCorners?: boolean;
  theme?: string;
}

const themesOptions: Record<string, ThemeOptions> = {
  [THEMES.LIGHT]: lightThemeOptions,
  [THEMES.DARK]: darkThemeOptions
};

const createTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions[config.theme ?? 'wer'];

  if (!themeOptions) {
    // eslint-disable-next-line no-console
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  let theme = createMuiTheme(
    merge(
      {},
      baseThemeOptions,
      themeOptions,
      {
        ...(config.roundedCorners && {
          shape: {
            borderRadius: 16,
          },
        }),
      },
      {
        direction: config.direction,
      },
    ),
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};

export const theme = createTheme({
  direction: 'ltr',
  responsiveFontSizes: true,
  roundedCorners: true,
  theme: 'LIGHT',
});

theme.shape.borderRadius = 8;
