import useRoutes from './routes/RootRouters';
import { useAppSelector } from './app/hooks';
import Notification from './components/Notification';

function App() {
  const { showNotification } = useAppSelector(({ notification }) => ({
    showNotification: notification.show,
  }));
  const routs = useRoutes();

  return (
    <>
      {showNotification && <Notification />}
      {routs}
    </>
  );
}

export default App;
