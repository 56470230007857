import { makeStyles, Theme } from '@material-ui/core';
import { Mixin } from '@monorepo/common';

const drawerWidth = 240;
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  overlay: {
    background: 'transparent',
    transition: '.4s',
    position: 'absolute',
    display: 'block',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    cursor: 'pointer',
    zIndex: 2,
    '&$active': {
      background: 'rgba(0, 0, 0, .5)',
    }
  },
  active: {},
  content: {
    flexGrow: 1,
    transition: '.8s transform',
  },
  work_space: {
    padding: '15px',
    '@media(max-width: 768px)': {
      padding: '10px',
    }
  },
  work_zone: {
    padding: '3rem 1.5rem',
    '@media(max-width: 768px)': {
      padding: '1rem .5rem 2rem',
    }
  },
  menuOpen: {
    opacity: 0.9,
    transform: 'scale(.95)',
    pointerEvents: 'none',
    zIndex: 0,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '@media(max-width: 960px)': {
      position: 'fixed',
      zIndex: 3,
      '& $drawerPaper': {
        left: '-100%'
      },
      '&$active': {
        ...Mixin.mixin.bg(),
        '& $drawerPaper': {
          left: 0
        }
      }
    }
  },
  drawerPaper: {
    width: drawerWidth,
    transition: '.3s left',
    border: 0,
    flexDirection: 'row'
  },
  drawerContainer: {
    overflow: 'auto',
    width: '100%',
    display: 'flex'
  },
}));
