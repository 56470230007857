import { memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import GoogleLogin from 'react-google-login';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Typography } from '@mui/material';
import { config, FormField, Loader } from '@monorepo/common';
import { useGooglePostMutation, useTrialPostMutation } from '../../../../services/trial';
import { showNotification } from '../../../../features/ui/sliceNotification';
import { IEventsStepProps } from '../../interfaceSteps';
import { ArrowRightIcon } from '../../components/ArrowRightIcon';
import { schema } from './schema';
import { useStyles } from '../../styles';

interface IStep1Props extends IEventsStepProps {
  setTrialGuid: (value: string) => void,
}

interface IConfirmEmailFormProps {
  email: string,
}

const Step1 = ({ onNext, setTrialGuid }: IStep1Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [post, { data: result, isLoading }] = useTrialPostMutation();
  const [googlePost, { data: googleResult, isLoading: isGoogleLoading }] = useGooglePostMutation();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IConfirmEmailFormProps>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: IConfirmEmailFormProps) => {
    post({ ...data }).then((res: any) => {
      if (res.error) {
        dispatch(showNotification({
          type: 'error',
          show: true,
          text: res.error?.data || 'Error'
        }));
      }
    });
  };

  const onGoogleSuccess = (req: any) => {
    googlePost({ jwtFromGoogle: req.tokenId }).then((res: any) => {
      console.log('-', res);
      if (res.error) {
        dispatch(showNotification({
          type: 'error',
          show: true,
          text: res.error?.error || 'Error'
        }));
      }
    });
  };

  const onGoogleFail = (errorResult: any) => {
    console.log(`Google login error: ${errorResult.error} ${errorResult.details}`);
  };

  useEffect(() => {
    if (result) {
      setTrialGuid(result.trialGuid);
      onNext();
    }
  }, [result]);

  useEffect(() => {
    if (googleResult) {
      setTrialGuid(googleResult.trialGuid);
      onNext();
    }
  }, [googleResult]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classes.signup_form}
    >
      <Loader isShow={isLoading || isGoogleLoading} />
      <Typography variant="h5">
        Let&apos;s start with your email
      </Typography>
      <FormField
        extraProps={{ ...register('email') }}
        errors={errors}
        label="Email *"
        description="This will be your username."
      />
      or
      <GoogleLogin
        className={classes.googleBtn}
        clientId={config.apiGoogleClientId}
        buttonText="Sign in with Google"
        onSuccess={onGoogleSuccess}
        onFailure={onGoogleFail}
        cookiePolicy="single_host_origin"
      />
      <Button
        endIcon={(<ArrowRightIcon fontSize="small" />)}
        variant="contained"
        type="submit"
        sx={{ mt: 3 }}
      >
        Next step
      </Button>
    </form>
  );
};

export default memo(Step1);
