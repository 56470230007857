import React from 'react';
import { useStyles } from '../styles';

interface IModalWindowOverlayProps {
  closeModalWindow: () => void
}
const ModalWindowOverlay = ({ closeModalWindow }:IModalWindowOverlayProps) => {
  const classes = useStyles();
  return (
     <div
        className={classes.overlay}
        onClick={closeModalWindow}
        role="presentation"
     />
  );
};

export default ModalWindowOverlay;
