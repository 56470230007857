import { memo } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';

import { IChildrenProps } from '@monorepo/common/src/interfaces';

import { useStyles } from './styles';

const Layout = ({
  children,
}: IChildrenProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <main className={clsx(classes.content)}>
        <CssBaseline />
        <div className={clsx(classes.work_zone)}>
          {children}
        </div>
      </main>
    </div>
  );
};

export default memo(Layout);
