export interface INotificationReducer {
  type: 'success' | 'error' | 'warning' | 'info',
  text: string | number,
  show?: boolean,
  fixed?: boolean,
  positionCenter?: boolean,
  delayClose?: number | null
}
export const notificationInitialState:INotificationReducer = {
  type: 'info',
  text: '',
  show: false,
  fixed: false,
  positionCenter: false,
  delayClose: 5000
};
