import React, { memo, useEffect, useState } from 'react';

import { IChildrenProps } from '../../interfaces';

import ModalWindowOverlay from './components/ModalWindowOverlay';
import ModalWindowBody from './components/ModalWindowBody';
import ModalWindowWrapper from './components/ModalWindowWrapper';
// import { useStyles } from './styles';

interface IPopupProps extends IChildrenProps{
  show: boolean,
  title?: string | number | null,
  closeModal: () => void,
  text?: string | number | null,
  errorMode?: boolean
}

const ModalWindow = ({ children, show, title, text, errorMode, closeModal }:IPopupProps) => {
  // const classes = useStyles();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const closeModalWindow = () => {
    setIsVisible(false);

    const myTimeout = setTimeout(() => {
      setShowModal(false);
      closeModal();
      clearTimeout(myTimeout);
    }, 400);
  };

  useEffect(() => {
    if (show) {
      // document.body.className = classes.scrollDisabled;
      setShowModal(true);
      return;
    }
    setShowModal(false);
    // document.body.className = '';
  }, [show]);

  // for hide scroll in body
  useEffect(() => {
    if (showModal) {
      const myTimeout = setTimeout(() => {
        setIsVisible(true);
        clearTimeout(myTimeout);
      }, 100);
    }
  }, [showModal]);

  if (!showModal) {
    return null;
  }
  return (
     <ModalWindowWrapper isVisible={isVisible}>
        <ModalWindowOverlay closeModalWindow={closeModalWindow} />
        <ModalWindowBody
           closeModalWindow={closeModalWindow}
           text={text}
           error={errorMode}
           title={title}
        >
           {children}
        </ModalWindowBody>
     </ModalWindowWrapper>
  );
};

export default memo(ModalWindow);
