import { memo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Loader } from '@monorepo/common';
import { useSuccessLinkQuery } from '../../../../services/trial';
import { useStyles } from '../../styles';

interface IFinishProps {
  trialGuid: string,
  regionId: number,
}

const Finish = ({ trialGuid, regionId }: IFinishProps) => {
  const classes = useStyles();

  const { data, isLoading } = useSuccessLinkQuery({ regionId: +regionId!, trialGuid: trialGuid! });

  return (
    <Box className={classes.signup_complete}>
      <Loader isShow={isLoading} />
      <Typography variant="h4">
        Welcome to Checkbox!
      </Typography>
      <Typography>
        Thanks for signing up for a free trial of Checkbox Survey!
      </Typography>
      <Typography>
        <span>Your free trial will be active for </span>
        <strong>14 days. </strong>
        <span>During this time, you’ll have complete access to all of our features. You can upgrade your account to a paid plan at any time and keep all of your work – just click the </span>
        <strong>“Upgrade Account” </strong>
        <span>option under your username or email us at </span>
        <a href="mailto:sales@checkbox.com">sales@checkbox.com </a>
        <span>for invoicing options.</span>
      </Typography>
      <Typography>
        <span>To help get you started, check out our </span>
        <a
          href="https://www.checkbox.com/support/checkbox-7-help-guides/quick-start-full-guide/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Quick Start Guide
        </a>
        <span>. Feel free to </span>
        <a
          href="https://www.checkbox.com/contact-us/"
          target="_blank"
          rel="noopener noreferrer"
        >
          contact us
        </a>
        <span> anytime with questions or feedback.</span>
      </Typography>
      <Typography
        variant="h4"
        mt="42px"
      >
        Accessing Your Account
      </Typography>
      <Typography>
        <span>To log into your account, you’ll use the Account Name you chose when you created your trial. </span>
        <strong>Make sure you save your Account Name in a safe place.</strong>
      </Typography>
      <Typography className={classes.signup_complete_info}>
        <strong>Your Checkbox Account Name: </strong>
        <span>{data?.accountName}</span>
        <br />
        <strong>Your Username: </strong>
        <span>{data?.username}</span>
      </Typography>
      {data && (
        <Box sx={{ display: 'inline-block' }}>
          <Button
            href={data.link}
            variant="contained"
          >
            GO TO MY ACCOUNT
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default memo(Finish);
