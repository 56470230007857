import React from 'react';
import clsx from 'clsx';

import { IChildrenProps } from '../../../interfaces';

import { useStyles } from '../styles';

interface IModalWindowWrapperProps extends IChildrenProps {
  isVisible: boolean
}
const ModalWindowWrapper = ({ children, isVisible }:IModalWindowWrapperProps) => {
  const classes = useStyles();
  return (
     <div className={clsx(classes.modal_window, {
       [classes.active]: isVisible
     })}
     >
        {children}
     </div>
  );
};

export default ModalWindowWrapper;
