import { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce, Button, FormControl, InputLabel, MenuItem, Select, Typography, FormHelperText, Checkbox, Box } from '@mui/material';
import { FormField, Loader, ModalWindow } from '@monorepo/common';
import { useGetRegionsQuery, useIsAccountNameAvailableQuery, useSetupPostMutation } from '../../../../services/trial';
import { showNotification } from '../../../../features/ui/sliceNotification';
import { ArrowRightIcon } from '../../components/ArrowRightIcon';
import { IEventsStepProps } from '../../interfaceSteps';
import { useStyles } from '../../styles';
import { schema } from './schema';

interface IStep3Props extends IEventsStepProps {
  trialGuid: string,
  setRegionId: (value: number) => void,
}

interface ISetupFormProps {
  accountName: string,
}

const Step3 = ({ onNext, trialGuid, setRegionId }: IStep3Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [accountName, setAccountName] = useState('');
  const [region, setRegion] = useState('');
  const [isAgreedForTOS, setIsAgreedForTOS] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { data: regionsData, isLoading: isRegionsDataLoading } = useGetRegionsQuery('');
  const { data: isAccountNameAvailable, isLoading: isIsAccountNameAvailableLoading } = useIsAccountNameAvailableQuery({ regionId: +region, accountName }, { skip: !region || !accountName });
  const [post, { data: result, isLoading }] = useSetupPostMutation();

  const {
    trigger,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ISetupFormProps>({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: ISetupFormProps) => {
    if (!isAgreedForTOS) {
      setShowModal(true);
      return;
    }

    post({ trialGuid: trialGuid!, regionId: +region, ...data }).then((res: any) => {
      if (res.error) {
        dispatch(showNotification({
          type: 'error',
          show: true,
          text: res.error?.data || 'Error'
        }));
      } else {
        dispatch(showNotification({
          text: 'Saved successfully',
          type: 'success',
          show: true
        }));
      }
    });
  };

  const onNameChange = useMemo(() => debounce((value: string) => {
    trigger('accountName');

    if (/^(\w+$)/g.test(value)) {
      setAccountName(value);
    }
  }, 800), []);

  useEffect(() => {
    if (regionsData && !isLoading) {
      setRegion(regionsData[0].value);
    }
  }, [regionsData, isLoading]);

  useEffect(() => {
    if (result) {
      setRegionId(result.regionId);
      onNext();
    }
  }, [result]);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={classes.signup_form}
    >
      <Loader isShow={isLoading || isRegionsDataLoading || isIsAccountNameAvailableLoading} />
      <ModalWindow
        show={showModal}
        title="Please accept the Checkbox Online SLA before proceeding."
        closeModal={() => setShowModal(false)}
      >
        <Button onClick={() => setShowModal(false)}>Ok</Button>
      </ModalWindow>
      <Typography variant="h5">
        Please set up your trial
      </Typography>
      <FormField
        extraProps={{ ...register('accountName') }}
        errors={errors}
        label="Account Name *"
        description="It's common to use your company or brand name as your account name. The account name will be needed for login and will also be part of your survey URLs."
        eventInput={onNameChange}
      />
      <FormControl fullWidth>
        <InputLabel id="region-select-label">Region *</InputLabel>
        <Select
          labelId="region-select-label"
          className={classes.region_select}
          value={region}
          label="Region *"
          onChange={(event) => {
            setRegion(event.target.value);
          }}
        >
          {regionsData && regionsData.map((r) => (
            <MenuItem
              key={r.value}
              value={r.value}
            >
              {r.text}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>You will be able to access your account from anywhere, but we let you choose where your survey data is stored.</FormHelperText>
      </FormControl>
      {
        !isAccountNameAvailable && accountName && (
          <p style={{ color: '#d32f2f', marginBottom: 10 }}>We’re sorry, your requested account name is already taken. Please try another option.</p>
        )
      }
      <Box className={classes.checkbox_wrapper}>
        <Checkbox
          value={isAgreedForTOS}
          onChange={(event) => setIsAgreedForTOS(event.target.checked)}
        />
        <Typography>
          <span>By signing up you agree to our </span>
          <a
            href="https://www.checkbox.com/online-sla/"
            target="_blank"
            rel="noopener noreferrer"
          >
            terms and conditions
          </a>
          <span> and </span>
          <a
            href="https://www.checkbox.com/privacy_policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy policy
          </a>
          <span>. We will never share or sell your information or data.</span>
        </Typography>
      </Box>
      <Button
        endIcon={(<ArrowRightIcon fontSize="small" />)}
        variant="contained"
        disabled={!!(!isAccountNameAvailable && accountName)}
        type="submit"
      >
        Next step
      </Button>
    </form>
  );
};

export default memo(Step3);
