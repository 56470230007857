import { memo } from 'react';
import { Avatar, StepIconProps } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

interface IStepIconProps extends StepIconProps { }

const StepIcon = ({ icon, completed }: IStepIconProps) => (
  <Avatar
    sx={{
      backgroundColor: 'secondary.main',
      height: 40,
      width: 40
    }}
    variant="rounded"
  >
    {
      completed
        ? <CheckIcon fontSize="small" />
        : icon
    }
  </Avatar>
);

export default memo(StepIcon);
