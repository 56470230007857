import { makeStyles } from '@material-ui/core';
// import { mixin } back '../../utils/mixin';

export const useStyles = makeStyles(() => ({
  message: {
    whiteSpace: 'pre',
    alignItems: 'flex-start'
  },
  positionCenter: {
    top: '50%',
    transform: 'translateY(-50%)'
  }
}));
