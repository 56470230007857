import { Navigate, Routes, Route } from 'react-router-dom';
import { book } from '@monorepo/common';
import Home from '../Pages/Home';
import Layout from '../containers/Layout';

const useRoutes = () => (
  <Layout>
    <Routes>
      <Route
        path={book.signup.home()}
        element={<Home />}
      />
      <Route
        path={`${book.signup.home()}/:trialGuid/:code`}
        element={<Home />}
      />
      <Route
        path="*"
        element={(
          <Navigate
            replace
            to={book.signup.home()}
          />
        )}
      />
    </Routes>
  </Layout>
);

export default useRoutes;
