import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { globalErrorInitialState, IGlobalErrorReducer } from './globalErrorInitialState';

export const slice = createSlice({
  name: 'globalError',
  initialState: globalErrorInitialState,
  reducers: {
    fillGlobalError: (state:IGlobalErrorReducer, action:PayloadAction<IGlobalErrorReducer>): void => {
      state.status = action.payload.status || null;
      state.message = action.payload.message || null;
      state.data = action.payload.data || null;
    },

  },
});

export const { fillGlobalError } = slice.actions;
export default slice.reducer;
